import { IconName } from '@hubtype/ui-react-web'
import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { AVAILABLE_CHANNELS, Provider } from '../../../UI/constants'
import { IdMapping } from '../../../UI/types'
import { HtFunctionNode } from '../cms/hubtype/function'
import { HtContentType } from '../cms/hubtype/node-types'
import { ContentType, TopContentFieldsBase } from '.'
import { CONDITIONAL_FUNCTIONS } from './common'
import { ConditionalContentType } from './content-types'

export interface ChannelInfo {
  id: Provider
  icon: IconName
  name: string
}

export class ChannelFields extends TopContentFieldsBase {
  constructor(public channels: ChannelInfo[] = []) {
    super()
  }

  contentType(): ConditionalContentType {
    return ContentType.CHANNEL
  }

  stringContentType(): string {
    return 'channel condition'
  }

  static fromHubtypeCMS(component: HtFunctionNode): ChannelFields {
    const newChannel = new ChannelFields()
    this.setHubtypeCmsCommonData(newChannel, component)
    const channels = []
    for (const result of component.content.result_mapping) {
      const channelFound = AVAILABLE_CHANNELS.find(
        channel => channel.id === result.result
      )
      if (channelFound) {
        channels.push(channelFound)
      }
    }
    newChannel.channels = channels
    return newChannel
  }

  toHubtypeCMS(position: XYPosition): HtFunctionNode {
    const node: HtFunctionNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.FUNCTION,
      content: {
        action: CONDITIONAL_FUNCTIONS.CHECK_CHANNEL_TYPE,
        arguments: [],
        result_mapping: [],
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('content ID')
    if (this.channels.length === 0) requiredFields.push('channels')
    if (!this.edges.some(edge => edge.sourceHandle?.includes('default'))) {
      requiredFields.push("'any other channels'")
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.code], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return this.getConditionalConnections(this.channels, edges)
  }

  static getCopy(content: ChannelFields): ChannelFields {
    const newChannel = new ChannelFields()
    TopContentFieldsBase.copyContent(content, newChannel)
    return newChannel
  }

  getIdMappingForOldFields(oldChannel: ChannelFields): IdMapping {
    return this.getConditionalIdMappings(
      this.channels,
      oldChannel.channels,
      oldChannel.id
    )
  }
}
